import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';

import Slide from 'components/Animations/Slide';
import Block from 'components/Block';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { COOKIE_CONSENT_COOKIE_NAME, MODAL_ROOT_ID } from 'consts';
import useIsClient from 'hooks/useIsClient';
import CloseIcon from 'icons/close.svg';
import { getInlineScripts } from 'utils';
import styles from './CookieBar.module.css';

export type CookieBarProps = React.HTMLProps<HTMLDivElement>;

const ActualCookieBar: React.FCWithChildren<CookieBarProps> = props => {
  const [ok, setOk] = React.useState<null | boolean>(null);

  const consent = (accept: boolean) => {
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, JSON.stringify(accept));
    setOk(true);

    // Load google analytics script if user accepts cookies
    if (accept) {
      new Function(getInlineScripts())();
    }
  };

  return (
    <>
      <Slide in={ok === null}>
        <Block background="white" className={styles.root} {...props}>
          <Block className={styles.contentWrapper}>
            <Typography variant="mid" className={styles.cookieText}>
              We use cookies to ensure that we give you the best experience on our website. If you
              continue to use this site we will assume that you are happy with it.
            </Typography>
            <Button color="black" onClick={() => consent(true)} className={styles.button}>
              OK
            </Button>
          </Block>
          <CloseIcon className={styles.closeIcon} onClick={() => consent(false)} />
        </Block>
      </Slide>
    </>
  );
};

const CookieBar: React.FCWithChildren<CookieBarProps> = props => {
  const client = useIsClient();
  const showCookieBar = !['true', 'false'].includes(
    String(Cookies.get(COOKIE_CONSENT_COOKIE_NAME)),
  );
  const acceptedCookies = Cookies.get(COOKIE_CONSENT_COOKIE_NAME) === 'true';

  if (client && showCookieBar) {
    // Render in using a portal in a separate react tree.
    const ROOT = typeof window !== 'undefined' ? document.getElementById(MODAL_ROOT_ID) : null;
    if (ROOT) {
      return ReactDOM.createPortal(<ActualCookieBar {...props} />, ROOT);
    }
  } else if (client && !showCookieBar && acceptedCookies) {
    new Function(getInlineScripts())();
  }
  return null;
};

export default CookieBar;
