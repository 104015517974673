import request from 'api/request';
import { DesignerDetail } from 'types/Designer';

export const getDesignerDetail = async (slug: string) => {
  return request<DesignerDetail>('GET', `designer/${slug}/`);
};

export const getDesignerList = async (data: { is_featured?: boolean } = {}) => {
  return request<DesignerDetail[]>('GET', `designer/`, data);
};
