import { TransformedMenuItem } from 'types/Menu';
import getLocalStorage from 'utils/getLocalStorage';
import setLocalStorage from 'utils/setLocalStorage';

const STORE_KEY = 'storage';

export type SlugMap = Record<string, TransformedMenuItem>;

export interface Store {
  menu: TransformedMenuItem[];
  slugMap: SlugMap;
}

class Storage {
  get(): Store | null {
    return getLocalStorage(STORE_KEY);
  }

  set(store: Store) {
    setLocalStorage<Store>(STORE_KEY, store);
  }
}

const storage = new Storage();

export default storage;
