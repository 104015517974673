interface DesignerNames {
  firstname: string;
  lastname: string;
}

const stringifyMultipleDesigners = (designers: DesignerNames[]) => {
  return designers.map(d => `${d.firstname} ${d.lastname}`).join(', ');
};

export default stringifyMultipleDesigners;
