import React from 'react';
import type { AppContext } from 'next/app';
import NProgress from 'nprogress';

import { getCategoryList } from 'api';
import CookieBar from 'components/CookieBar';
import AppProvider from 'containers/AppProvider';
import { TransformedMenuItem } from 'types/Menu';
import { EnhancedAppProps, EnhancedPageContext } from 'types/Next';
import { shouldUseMSW, storage, transformMenu } from 'utils';

const IS_CLIENT = typeof window !== 'undefined';

import { useRouter } from 'next/router';

import EnquiryProvider from 'containers/EnquiryProvider';
import { SlugMap, Store } from 'utils/storage';

import '../styles/global.css';

if (shouldUseMSW()) {
  import('../tests/mocks');
}

JacksonApp.getInitialProps = async ({ ctx: passedCtx, Component }: AppContext) => {
  let slugMap: SlugMap = {};
  let menu: TransformedMenuItem[] = [];

  if (IS_CLIENT) {
    const store = storage.get();

    if (store) {
      slugMap = store.slugMap;
      menu = store.menu;
    }
  }

  const hasSlugs = Object.values(slugMap).length;
  const menuResponse = !hasSlugs ? await getCategoryList() : undefined;

  if (menuResponse?.data) {
    const transformed = transformMenu(menuResponse.data);

    slugMap = transformed.slugMap;
    menu = transformed.menu;
  }

  const appStore: Store = { slugMap, menu };

  const ctx: EnhancedPageContext = { ...passedCtx, store: appStore };

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    store: appStore,
    pageProps,
  };
};

function JacksonApp({ Component, pageProps, store, err }: EnhancedAppProps) {
  const router = useRouter();

  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });
  }, [router.events]);

  return (
    <>
      <AppProvider store={store}>
        <EnquiryProvider>
          <Component {...pageProps} err={err} />
        </EnquiryProvider>
      </AppProvider>
      <CookieBar />
    </>
  );
}

export default JacksonApp;
