import { DESIGNERS, EXHIBITIONS, STORIES } from 'routes';

export const CSRF_COOKIE_NAME = 'csrftoken';
export const MODAL_ROOT_ID = 'modal-root';
export const CLOSE_KEYS = ['Undo', 'Escape', 'Cancel', 'GoBack', 'GoHome'];
export const TITLE_BASE = 'Jackson Design';
export const MOCK_API_URL = 'http://localhost:8000/mock-api';
export const VIEMO_BASE_URL = `https://player.vimeo.com/video/`;
export const COOKIE_CONSENT_COOKIE_NAME = 'cookies_ok';
export const DEFAULT_REVALIDATE_TIMEOUT = 60;
export const ENQUIRY_KEY = 'enquiry';
export const PAGE_SIZES = [24, 48, 96];
export const DEFAULT_PAGE_SIZE = PAGE_SIZES[0];

export const STATIC_NAV_ITEMS = [
  { name: 'Exhibitions', href: EXHIBITIONS.pathname },
  { name: 'Stories', href: STORIES.pathname },
  { name: 'Designers', href: DESIGNERS.pathname },
];

export const MINUTE = 1000 * 60;
