import React, { HTMLAttributes } from 'react';

export type ClickableProps = {
  href?: string;
  disabled?: boolean;
} & HTMLAttributes<HTMLAnchorElement & HTMLButtonElement>;

/**
 * Clickable
 * An unstyled component that automatically switches between `<a>` and
 * `<button>` based on the presence of a `href` and automatically applies some
 * nice attribute defaults.`.
 *
 * Wrap components with this when implementing them, rather than
 * building a Link or button into them, which makes them more reusable.
 */
const Clickable = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, ClickableProps>(
  ({ href, disabled, ...restProps }, ref) => {
    return href != null ? (
      disabled ? (
        // Support `disabled` on links by removing the `href`. Also override
        // `onClick` to match `<button disabled>`. There might be more events to
        <a
          ref={ref}
          {...restProps}
          aria-disabled={disabled}
          onClick={() => {
            /*nothing*/
          }}
        />
      ) : (
        <a ref={ref} href={href} {...restProps} />
      )
    ) : (
      // `type` can be overridden via `restProps`.
      <button ref={ref} type="button" aria-disabled={disabled} disabled={disabled} {...restProps} />
    );
  },
);

Clickable.displayName = 'Clickable';

export default Clickable;
