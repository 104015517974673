import request from 'api/request';
import { Paginated } from 'types/Paginated';
import { Product } from 'types/Product';

export const getProductDetail = async ({ id }: { id: string }) => {
  return request<Product>('GET', `product/${id}/`);
};

type GetProductOptions = Partial<{
  designers?: number[];
  producer?: number;
  page?: string | number;
  category?: number;
  search?: string;
  page_size?: number;
  width_min?: number;
  width_max?: number;
  is_featured?: boolean;
}>;

export const getProductList = async ({
  designers,
  producer,
  page = 1,
  category,
  search = '',
  page_size,
  width_min,
  width_max,
  is_featured,
}: GetProductOptions = {}) => {
  return request<Paginated<Product>>('GET', 'product/', {
    designers,
    brand: producer, // What is elsewhere referred to as "producer" is "brand" in backend/database
    page,
    category,
    search,
    page_size,
    width_min,
    width_max,
    is_featured,
  });
};
