/* eslint-disable css-modules/no-undef-class */
import ReactDOM from 'react-dom';

import Toaster, { ToasterVariants } from 'components/Toaster';
import styles from 'components/Toaster/Toaster.module.css';
import { MODAL_ROOT_ID } from 'consts';
import cx from 'utils/cx';

const TRANSITION_TIME = 150; // ms
const TOASTER_ID = 'toaster';

const ROOT = typeof window !== 'undefined' ? document.getElementById(MODAL_ROOT_ID) : null;

const removePrevious = () => {
  for (const previous of Array.from(document.querySelectorAll(`#${TOASTER_ID}`))) {
    previous.classList.remove(styles.open);

    setTimeout(() => {
      // FIXME: Bump this and listen to the linter. Probably makes sense to use a portal
      /* eslint-disable react/no-deprecated */
      ReactDOM.unmountComponentAtNode(previous);
      previous.parentNode?.removeChild(previous);
    }, TRANSITION_TIME);
  }
};

const toast = (children = 'Something went wrong. Please try again.', variant?: ToasterVariants) => {
  if (!ROOT) return null;

  const element = document.createElement('div');
  element.id = TOASTER_ID;
  element.classList.add(cx(styles.fixed));
  element.style.transition = `transform ${TRANSITION_TIME}ms`;

  removePrevious();

  // FIXME: Bump this and listen to the linter. Probably makes sense to use a portal
  /* eslint-disable react/no-deprecated */
  ReactDOM.render(
    <Toaster
      onClose={() => {
        removePrevious();
      }}
      variant={variant || 'error'}
    >
      {children}
    </Toaster>,
    element,
  );

  ROOT.appendChild(element);

  setTimeout(() => {
    element.classList.add(styles.open);
  }, TRANSITION_TIME);

  return null;
};

export default toast;
