export const HOME = {
  pathname: '/',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const PRODUCTS = {
  pathname: '/products',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const PRODUCT_CATEGORY = {
  pathname: 'products/category/[...path]',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const PRODUCT = {
  pathname: '/products/[id]',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const SEARCH = {
  pathname: '/search/result',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const DESIGNERS = {
  pathname: '/designers',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const DESIGNER = {
  pathname: '/designers/[slug]',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const PRIVACY = {
  pathname: '/privacy',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const PRODUCER = {
  pathname: '/producers/[slug]',
  requiresLogin: false,
  hideFromSitemap: false,
};
export const PRODUCERS = {
  pathname: '/producers',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const EXHIBITION = {
  pathname: '/exhibitions/[slug]',
  requiresLogin: false,
  hideFromSitemap: false,
};
export const EXHIBITIONS = {
  pathname: '/exhibitions',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const STORY = {
  pathname: '/stories/[slug]',
  requiresLogin: false,
  hideFromSitemap: false,
};
export const STORIES = {
  pathname: '/stories',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const ABOUT = {
  pathname: '/about',
  requiresLogin: false,
  hideFromSitemap: false,
};

export const CONTACT = {
  pathname: '/contact',
  requiresLogin: false,
  hideFromSitemap: false,
};
