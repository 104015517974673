import React from 'react';
import cx from 'classnames';

import { restoreLinebreaks } from 'utils';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Typography.module.css';

export interface TypographyProps extends React.HTMLProps<HTMLSpanElement> {
  component?: React.ElementType;
  variant?: 'small' | 'big' | 'mid' | 'sub' | 'heading' | 'newspaper' | 'tiny' | 'button';
  block?: boolean;
  className?: string;
  align?: 'inherit' | 'left' | 'right' | 'center';
  pre?: boolean;
  uppercase?: boolean;
  color?: 'inherit' | 'faded' | 'off-white';
  gutter?: 1 | 2 | 3;
  setInnerHtml?: boolean;
}

/**
 * Typography
 *  Renders a text with a variant as a <span> if nothing else is specificed.
 *  The idea:
 * - Don't assume the document hierarchy, let the user set proper tags for SEO when applicable
 * - Use only one prop that infers the styling of the elemnent itself, the variant. Other
 *   props never alter the look of the text itself, just how it interacts with elements around it.
 * - All fontSizes are rems for easier resizing.
 *
 * @param component string/ReactElement Underlying component, defaults to a <span>
 * @param variant string preset-style
 * @param block boolean display as a block-level element
 * @param className string className passed down to the root element
 * @param align string implementation of css text-align
 * @param pre boolean text is pre-formatted, honors line-breaks and spaces.
 * @param uppercase boolean render as uppercase
 * @param color color, defaults to inherit.
 * @param gutter number of rems to add as margin-bottom
 * @param setInnerHtml boolean if true, dangerouslySetInnerHTML is used to render children
 * @returns
 */
const Typography: React.FCWithChildren<TypographyProps> = ({
  component,
  variant,
  children,
  className,
  align,
  block,
  pre,
  color,
  uppercase,
  gutter,
  setInnerHtml = false,
  ...rest
}) => {
  const Component = component || 'span';

  const classNameProps = cx(
    styles.root,
    styles[`variant-${variant}`],
    styles[`align-${align}`],
    {
      [styles['color-faded']]: color === 'faded',
      [styles['color-off-white']]: color === 'off-white',
      [styles.pre]: pre,
      [styles.uppercase]: uppercase,
      [styles.block]: block,
      [styles[`gutter-${gutter}`]]: gutter,
    },
    className,
  );

  if (setInnerHtml) {
    return (
      <Component
        className={classNameProps}
        dangerouslySetInnerHTML={{ __html: restoreLinebreaks(children as string) }}
        {...rest}
      />
    );
  }

  return (
    <Component className={classNameProps} {...rest}>
      {children}
    </Component>
  );
};

export default Typography;
