import React from 'react';

import AppContext from 'contexts/AppContext';
import { storage } from 'utils';
import { Store } from 'utils/storage';

interface AppProviderProps {
  store: Store;
}

const AppProvider: React.FCWithChildren<AppProviderProps> = ({ children, store }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);

  React.useEffect(() => {
    storage.set(store);
  }, [store]);

  return (
    <AppContext.Provider value={{ menuOpen, setMenuOpen, store, searchOpen, setSearchOpen }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
