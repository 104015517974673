import { Picture } from 'types/Picture';
import makeDevImageSrc from 'utils/makeDevImgSrc';

export default function makeImageSources(picture: Picture) {
  const { image_960w, image_1920w } = picture;

  return {
    src: makeDevImageSrc(image_960w || ''),
    srcSet: `${makeDevImageSrc(image_960w || '')} 960w, ${makeDevImageSrc(
      image_1920w || '',
    )} 1920w`,
  };
}
