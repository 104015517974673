import React from 'react';
import { CSSTransition } from 'react-transition-group';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Slide.module.css';

export interface SlideProps {
  in: boolean;
  children: React.ReactNode;
  direction?: 'y' | 'x';
}

const Slide: React.FCWithChildren<SlideProps> = ({ in: passedIn, children, direction = 'y' }) => {
  const hiddenClass = styles[`hidden-${direction}`];
  return (
    <CSSTransition
      unmountOnExit
      mountOnEnter
      in={passedIn}
      timeout={{ enter: 150, exit: 100 }}
      classNames={{
        enter: hiddenClass,
        enterActive: styles.visible,
        enterDone: styles.visible,
        exit: styles.visible,
        exitDone: hiddenClass,
        exitActive: hiddenClass,
      }}
    >
      {children}
    </CSSTransition>
  );
};

export default Slide;
