import request from 'api/request';
import { ProducerDetail } from 'types/Producer';

export const getProducerDetail = async (slug: string) => {
  return request<ProducerDetail>('GET', `brand/${slug}/`);
};

export const getProducerList = async (data = {}) => {
  return request<ProducerDetail[]>('GET', `brand/`, data);
};
