import { NodeTreeItem } from 'djedi-json';

const sortExhibitions = (exhibtions: NodeTreeItem[]) => {
  return exhibtions.sort((a, b) => {
    const aDate = a.content.date || 1;
    const bDate = b.content.date || 1;

    const aTime = new Date(aDate).getTime();
    const bTime = new Date(bDate).getTime();

    return bTime - aTime;
  });
};

export default sortExhibitions;
