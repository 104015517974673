export class RequestError<T> extends Error {
  constructor(
    message: string,
    readonly response: T,
  ) {
    super(message);

    this.response = response;
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export interface RequestResponse<T> extends Response {
  data: T;
}

export interface RequestErrorInterface {
  message: string;
  response: Record<string, unknown>;
}

export class NotFoundError<T> extends Error {
  constructor(
    message: string,
    readonly response: T,
  ) {
    super(message);

    this.response = response;
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

// Based on: https://github.com/developit/unfetch/blob/92d9203e02edbd7ac6788be41dd629b5c8247299/src/index.mjs#L9-L24
export function cacheResponse<I = unknown, O = unknown>(
  cacheKey: string,
  fullUrl: string,
  inputData: I,
  outputData: O,
) {
  const bodyConsumed = () => Promise.reject(new TypeError('Body has already been consumed.'));

  return {
    ok: true,
    statusText: 'OK',
    status: 200,
    url: fullUrl,
    text: bodyConsumed,
    json: bodyConsumed,
    blob: bodyConsumed,
    clone: () => {
      throw new Error('.clone() is not implemented for cached responses.');
    },
    headers: {
      keys: () => [],
      entries: () => [],
      get: () => undefined,
      has: () => false,
    },
    toJSON() {
      return {};
    },
    __input: inputData,
    data: outputData,
    cacheKey,
    __fromCache: true,
  } as unknown as RequestResponse<O>;
}
