interface CookieObject {
  [key: string]: string;
}

const makeCookieString = (object: CookieObject) => {
  return Object.entries(object)
    .map(([key, value]) => `${key}=${value}`)
    .join('; ');
};

export default makeCookieString;
