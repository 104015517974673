// TYPES
export const DJEDI_EXHIBITION_TYPE = 'application/exhibition';
export const DJEDI_IMAGE_TYPE = 'media/image';
export const DJEDI_RICH_TEXT_TYPE = 'text/simple-rich-text';
export const DJEDI_LAYOUT_GRID_TYPE = 'layout/grid';
export const DJEDI_LAYOUT_GRID_ITEM_TYPE = 'layout/grid-item';
export const DJEDI_LAYOUT_CONTAINER_TYPE = 'layout/Container';
export const DJEDI_VIMEO_TYPE = 'media/vimeo';
export const DJEDI_OBJECT_TYPE = 'media/object';
export const DJEDI_PAGE_TYPE = 'application/page';
export const DJEDI_STORE_TYPE = 'application/store';

// PATHS
export const DJEDI_CONTACT_PATH = 'contact';
export const DJEDI_ABOUT_PATH = 'about';

// PAGES PATHS
export const DJEDI_PAGES_PATHS = [DJEDI_CONTACT_PATH, DJEDI_ABOUT_PATH];
