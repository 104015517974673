import React, { ReactNode } from 'react';

import Clickable, { ClickableProps } from 'components/Clickable';
import { cx } from 'utils';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Button.module.css';

export type ButtonProps = {
  className?: string;
  disabled?: boolean;
  color?: 'black' | 'white' | 'transparent';
  size?: 'small';
  block?: boolean;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  disablePadding?: boolean;
} & React.ComponentPropsWithoutRef<'button' | 'a'> &
  ClickableProps;

const Button: React.FCWithChildren<ButtonProps> = React.forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      disabled,
      className,
      color,
      block,
      size,
      endAdornment,
      startAdornment,
      disablePadding = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <Clickable
        ref={ref}
        className={cx(
          styles.root,
          styles[`color-${color}`],
          {
            [styles.block]: block,
            [styles.small]: size === 'small',
            [styles.disablePadding]: disablePadding,
          },

          className,
        )}
        disabled={disabled}
        {...rest}
      >
        <div className={styles.buttonContent}>
          {startAdornment && (
            <span className={cx(styles.adornment, styles.startAdornment)}>{startAdornment}</span>
          )}
          <span>{children}</span>
          {endAdornment && (
            <span className={cx(styles.adornment, styles.endAdornment)}>{endAdornment}</span>
          )}
        </div>
      </Clickable>
    );
  },
);

Button.displayName = 'Button';

export default Button;
