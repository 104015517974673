const REGEX = /(http[s]?:\/\/)?([^/\s]+\/)(.*)/g;
const BASE = 'http://jacksons.x:8000';

export default function makeDevImageSrc(imgSrc: string) {
  if (process.env.NODE_ENV === 'development') {
    const [result] = Array.from(imgSrc.matchAll(REGEX));
    const relative = result?.[3] || '';

    return `${BASE}/${relative}`;
  }

  return imgSrc;
}
