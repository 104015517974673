import React from 'react';

function joinElements<T = JSX.Element>(array: T[], joinWith: React.ReactNode) {
  return array.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <>
          {item}
          {index !== array.length - 1 && joinWith}
        </>
      </React.Fragment>
    );
  });
}

export default joinElements;
