import { MenuItem, TransformedMenuItem } from 'types/Menu';
import { SlugMap } from 'utils/storage';

export const makeSlug = (string: string) => string.split(' ').join('-').toLowerCase();

export const transformMenu = (menu: MenuItem[]) => {
  const slugMap: SlugMap = {};

  const transform = (
    children: MenuItem[],
    prev: string[] = [],
    parent?: number,
  ): TransformedMenuItem[] => {
    return children.map(child => {
      const hasChildren = child.children.length;

      const newPath = [...prev, child.name];
      const path = makeSlug(newPath.join('/'));

      const childWithPath = {
        ...child,
        path,
        parent,
        children: hasChildren ? transform(child.children, newPath, child.id) : child.children,
      } as TransformedMenuItem;

      slugMap[path] = childWithPath;

      return childWithPath;
    });
  };

  return {
    menu: transform(menu),
    slugMap,
  };
};

export default transformMenu;
