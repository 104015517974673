import React, { Dispatch, SetStateAction } from 'react';

import { Store } from 'utils/storage';

export interface AppContextProps {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  store: Store;
  searchOpen: boolean;
  setSearchOpen: Dispatch<SetStateAction<boolean>>;
}

const AppContext = React.createContext<AppContextProps>({
  menuOpen: false,
  searchOpen: false,
} as AppContextProps);

export default AppContext;

export const useApp = () => React.useContext(AppContext);
