// `<Field {...trimOnBlur}>`
export const trimOnBlur = {
  format: (value = '') => value.trim(),
  formatOnBlur: true,
};

// `<Field {...normalizePhoneOnBlur}>`
export const normalizePhoneOnBlur = {
  format: normalizePhone,
  formatOnBlur: true,
};

export function maxLength(
  message: string,
  length = Infinity,
): (value?: string) => undefined | string {
  return (value = '') => (value.length <= length ? undefined : message);
}

export function minLength(message: string, length = 0): (value?: string) => undefined | string {
  return (value = '') => (value.length >= length ? undefined : message);
}

function normalizePhone(value = '') {
  return value.trim().replace(/[\s-]/g, '');
}

type Validator<T> = (message: string) => (value?: T) => undefined | typeof message;

// Non-empty, and not just whitespace.
export const required: Validator<string> = message => {
  return value => (value != null && value.trim() !== '' ? undefined : message);
};

// Must be truthy.
export const requiredBoolean: Validator<boolean> = message => {
  return value => (value ? undefined : message);
};

// At least 10 digits. Can start with a plus. Allow spaces and dashes.
export const phoneIsh: Validator<string> = message => {
  return value =>
    value != null && /^\+?\d{10,}$/.test(normalizePhone(value)) ? undefined : message;
};

// whatever@whatever.whatever
// “whatever” after the “@” must not contain “@” or whitespace.
// https://en.wikipedia.org/wiki/Email_address#Examples
export const emailIsh: Validator<string> = message => {
  return value =>
    value != null && /^.*[^@\s]@[^@\s]+\.[^@\s.]+$/.test(value.trim()) ? undefined : message;
};

export function combineValidators<S>(...validators: Array<ReturnType<Validator<S>>>) {
  return (value: S) => {
    /* eslint-disable-next-line */
    for (const validator of validators) {
      const result = validator(value);
      if (result != null) {
        return result;
      }
    }
    return undefined;
  };
}
