import fs from 'fs';
import path from 'path';

export const MINUTE = 1000 * 60; // 1  minute

export interface ICache<T = unknown> {
  ttl: number;
  data: T;
}

export type CacheKey = string;

class Cache<C> {
  private cache = new Map<CacheKey, ICache<C>>();
  private readonly filePath = path.resolve('.next/.ssrCache.json');

  constructor(initialCache?: Map<string, ICache<C>>) {
    this.cache = initialCache ?? this.readFromFile() ?? new Map<CacheKey, ICache<C>>();
  }

  public readFromFile() {
    let stored: Map<CacheKey, ICache<C>> | null = null;

    try {
      stored = new Map(
        Object.entries(JSON.parse(fs.readFileSync(this.filePath, { encoding: 'utf-8' }))),
      );
    } catch (error) {
      // Do nothing
    }

    if (!stored) return null;

    return stored;
  }

  public saveToFile() {
    return fs.writeFileSync(this.filePath, JSON.stringify(Object.fromEntries(this.cache)), {
      encoding: 'utf-8',
    });
  }

  public set<T extends C>(key: CacheKey, data: T, ttl = MINUTE * 5) {
    this.cache.set(key, { data, ttl: Date.now() + ttl });
    this.saveToFile();
  }

  public get<T extends C>(key: CacheKey) {
    const cached: ICache<C> | undefined = this.cache.get(key);

    if (cached) {
      const { ttl, data } = cached;

      if (ttl > Date.now()) {
        return data as T;
      } else {
        this.cache.delete(key);
      }

      return null;
    }

    return null;
  }

  public clear() {
    this.cache.clear();
    this.saveToFile();
  }

  public delete(key: CacheKey) {
    this.cache.delete(key);
    this.saveToFile();
  }

  public getValue() {
    return this.cache;
  }
}

export default Cache;
