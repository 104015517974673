/**
 * Transforms a next string param from an array or string into a definite string.
 * This is mostly a typescript issue, since we usually have control of the param type.
 * Better safe than sorry.
 */
const makeStringParam = (param: string | string[] | undefined | null, delimiter = ''): string => {
  return param ? (Array.isArray(param) ? param.join(delimiter) : param) : '';
};

export default makeStringParam;
