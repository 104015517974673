import request from 'api/request';
import { EnquiryData, EnquiryMessagePayload, EnquiryOrder, EnquiryResponse } from 'types/Enquiry';

export const submitEnquiry = async (data: EnquiryData) => {
  return request<EnquiryResponse>('POST', 'order/new/', data);
};

export const getEnquiry = async ({ token }: { token: string }) => {
  return request<EnquiryOrder>('GET', `order/${token}/`);
};

export const getAllowedCountries = async () => {
  return request<string[]>('GET', 'order/allowed_countries');
};

export const submitEnquiryMessage = async (data: EnquiryMessagePayload) => {
  return request<EnquiryResponse>('POST', 'order/send_message/', data);
};
