import React from 'react';

import { cx } from 'utils';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Block.module.css';

export type BlockProps = {
  className?: string;
  disabled?: boolean;
  component?: React.ElementType;
  background?:
    | 'offblack'
    | 'black'
    | 'white'
    | 'offwhite'
    | 'midgrey'
    | 'red'
    | 'darkgrey'
    | 'darker';
  padding?: 'regular' | 'extra' | 'none';
} & React.HTMLProps<HTMLDivElement>;

/**
 * Block
 * Acts on the Y-axis considering padding,
 * the purpose of the block is to add tempo between sections and
 * to set the background color + control text color.
 */

const Block = ({
  className,
  background,
  padding = 'regular',
  component: Component = 'section',
  ...rest
}: BlockProps) => {
  return (
    <Component
      className={cx(
        styles.root,
        styles[`background-${background}`],
        styles[`padding-${padding}`],
        className,
      )}
      {...rest}
    />
  );
};

Block.displayName = 'Block';

export default Block;
