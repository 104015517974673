export default function chunkBy<T = unknown>(array: Array<T>, chunkSize: number) {
  return array.reduce<Array<T[]>>((arr, item, index) => {
    if (index % chunkSize === 0) {
      arr.push([]);
    }

    arr[arr.length - 1].push(item);

    return arr;
  }, []);
}
