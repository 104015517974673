import cookies from 'js-cookie';
import getConfig from 'next/config';

import { CSRF_COOKIE_NAME, MOCK_API_URL } from 'consts';
import Cache, { CacheKey, ICache } from 'utils/cache';
import {
  addQueryString,
  deleteNil,
  makeCookieString,
  makeQueryString,
  shouldUseMSW,
} from '../utils';
import { cacheResponse, NotFoundError, RequestError, RequestResponse } from './utils';

export type RequestReturnInterface<T> = {
  response: Response;
  data: T;
};

export interface RequestOptions<
  T,
  D extends Record<string, unknown> | FormData = Record<string, unknown>,
> {
  isBlob?: boolean;
  cookie?: Record<string, string>;
  cache?: {
    ttl?: ICache['ttl'];
    makeKey: (url: string, inputData?: D, outputData?: T) => CacheKey;
  };
}

const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getConfig() || {};

export const JSON_REGEX = /\bapplication\/json\b/;
const mockApiCalls = shouldUseMSW();

/** API BASE */
const isServer = serverRuntimeConfig.API_URL !== undefined;

const serverCacheStorage = isServer ? new Cache() : undefined;

const auth = Buffer.from(
  `${serverRuntimeConfig?.BASIC_AUTH_USER}:${serverRuntimeConfig?.BASIC_AUTH_PASSWORD}`,
).toString('base64');

async function request<T, D extends Record<string, unknown> | FormData = Record<string, unknown>>(
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  apiUrl: string,
  data?: D,
  options: RequestOptions<T, D> = {},
) {
  const isFile = !isServer && data instanceof FormData;
  const isGET = method === 'GET';
  const { isBlob, cookie = {}, cache } = options;

  const path =
    // @ts-expect-error Annoying TS error that will never happen
    data != null && isGET && !isFile ? addQueryString(apiUrl, makeQueryString(data)) : apiUrl;

  const url =
    (mockApiCalls ? MOCK_API_URL : serverRuntimeConfig?.API_URL || publicRuntimeConfig.API_URL) +
    `/${path}`;

  if (cache && serverCacheStorage) {
    const { makeKey } = cache;
    const key = makeKey(path, data);
    const entry = serverCacheStorage.get(key);

    if (entry) {
      // eslint-disable-next-line no-console
      console.info(`using cache for key [${key}]`);

      return cacheResponse(key, url, entry, entry) as RequestResponse<T>;
    }
  }

  const headers = {
    Authorization: isServer
      ? serverRuntimeConfig?.BASIC_AUTH_USER !== undefined &&
        serverRuntimeConfig.BASIC_AUTH_PASSWORD !== undefined
        ? `Basic ${auth}`
        : undefined
      : undefined,
    Cookie: !isServer ? document.cookie : makeCookieString(cookie),
    'Content-Type': isFile ? undefined : 'application/json',
    'X-CSRFToken': !isServer ? cookies.get(CSRF_COOKIE_NAME) : undefined,
  };

  return fetch(url, {
    credentials: 'include',
    method,
    headers: deleteNil(headers),
    body: data != null && !isGET && !isFile ? JSON.stringify(data) : isFile ? data : undefined,
  })
    .then(async rawResponse => {
      const response = rawResponse as RequestResponse<T>;
      const isJSON = JSON_REGEX.test(response.headers.get('Content-Type') || '');
      response.data = await (isJSON ? response.json() : isBlob ? response.blob() : response.text());

      if (response.status >= 200 && response.status < 400) {
        if (cache && serverCacheStorage) {
          const { makeKey, ttl } = cache;
          const key = makeKey(path, data, response.data);

          serverCacheStorage.set(key, response.data, ttl);
        }

        return response;
      } else if (response.status === 404) {
        throw new NotFoundError('Resource not found', response);
      }

      const error = new RequestError<RequestResponse<T>>(
        `Non-success status code: ${response.status}`,
        response,
      );

      throw error;
    })
    .catch(err => {
      throw err;
    });
}

export default request;
