import request from 'api/request';

export interface FeaturedImage {
  created_at: string;
  headline: string;
  id: number;
  image_landscape_960w: string;
  image_landscape_1920w: string;
  image_portrait_375w: string;
  image_portrait_750w: string;
  is_published: boolean;
  landscape_image: string;
  link_text: string;
  link_url: string;
  portrait_image: string;
  text_align: 'left' | 'center' | 'right';
  updated_at: string;
  video_url: string;
  btn_color: 'none' | 'white' | 'black';
}

export const getFeaturedImages = async () => {
  return request<FeaturedImage[]>('GET', `storefront-images/`);
};
