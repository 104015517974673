import React from 'react';

import CloseIcon from 'icons/close.svg';
import cx from 'utils/cx';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Toaster.module.css';

export type ToasterVariants = 'error';

export interface ToasterProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  variant?: ToasterVariants;
  children: React.ReactNode;
  disappearMs?: number;
  onClose?: () => void;
}

const Toaster = React.forwardRef<HTMLDivElement, ToasterProps>((props, ref) => {
  const { variant = 'error', children, disappearMs = 10000, onClose, ...restProps } = props;
  const [className, setClassName] = React.useState('');

  React.useEffect(() => {
    setTimeout(() => {
      setClassName(styles.removeAnimation);
    }, 50);

    if (onClose) {
      setTimeout(() => {
        onClose();
      }, disappearMs);
    }
  }, [disappearMs, onClose]);

  return (
    <div ref={ref} className={cx(styles.content, styles[`bg-${variant}`])} {...restProps}>
      {onClose && (
        <button onClick={() => onClose()} className={styles.close}>
          <CloseIcon />
        </button>
      )}
      <div>{children}</div>

      <div
        style={{ transition: `width linear ${disappearMs}ms` }}
        className={cx(styles.progress, className)}
      />
    </div>
  );
});

Toaster.displayName = 'Toaster';

export default Toaster;
