const IGNORED = [
  // safari and firefox warnings
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  // Caused by Pocket APP on iOS
  "Can't find variable: pktAnnotationHighlighter",
  // caused by ad-blocking bidtheatre
  'asxtrack is not defined',
  "null is not an object (evaluating 'h.getItem')",
  // Bing wrongful injects this:  https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight,
  "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
  // Error caused by unidentified tracking script
  "Identifier 'change_ua' has already been declared",
  // caused by 'Fair Ad Block'
  'Cannot redefine property: BetterJsPop',
  // Email link Microsoft Outlook crawler compatibility error https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
  // Misc. Network errors triggered when site is down or deployment is in progress
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  // Localized NetworkErrors ( some will always make it through ):
  'avbrutt',
  'geannuleerd',
  'avbruten',
  'annulleret',
  'cancelled',
  'anulowane',
];

export default IGNORED;
