export default function makeQueryString(data: Record<string, unknown>, encode = true) {
  return Object.entries(data)
    .map(([key, value]) =>
      value == null
        ? []
        : Array.isArray(value)
          ? value.map(subValue => [key, subValue])
          : [[key, value]],
    )
    .flat(1)
    .map(
      ([key, value]) =>
        `${encode ? encodeURIComponent(key) : key}=${
          encode ? encodeURIComponent(String(value)) : value
        }`,
    )
    .join('&');
}
