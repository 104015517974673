import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const GTM_CONTAINER_ID = publicRuntimeConfig.GTM_CONTAINER_ID;

export const getGoogleTagManagerScript = () => `
(function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', '${GTM_CONTAINER_ID}');`;

export const getInlineScripts = () => {
  return [GTM_CONTAINER_ID ? getGoogleTagManagerScript() : undefined].filter(Boolean).join('\n');
};
