import { NodeTreeItem } from 'djedi-json';

import request, { RequestOptions } from 'api/request';
import { DJEDI_EXHIBITION_TYPE, DJEDI_PAGE_TYPE } from 'consts/cms';
import { deleteNil } from 'utils';

const URL_PATH = 'djedi_pages/nodes/';

export enum ExhibitionVariant {
  EXHIBITION = 'EXHIBITION',
  STORY = 'STORY',
}

interface NodeEntity {
  values: NodeTreeItem;
  uri: string;
}
export const getExhibitionNodeAdmin = (
  {
    namespace = 'jacksons',
    path,
    variant,
    pageSize = 9999,
  }: {
    path?: string;
    namespace?: string;
    featured?: boolean;
    variant?: ExhibitionVariant;
    pageSize?: number;
  } = {},
  requestOptions: RequestOptions<Record<string, unknown>> = {},
) => {
  return request<{ results: NodeEntity[] }>(
    'POST',
    'admin/v1.0/djedi/node/query/',
    {
      filter: {
        page_size: pageSize,
        uri: { path, namespace: { l10n: namespace } },
        content: deleteNil({
          type: DJEDI_EXHIBITION_TYPE,
          'content.variant': variant,
        }),
      },
      values: ['content', 'type', 'meta'],
    },
    requestOptions,
  );
};

export const getExhibitionNode = ({
  namespace = 'jacksons',
  path,
  featured,
  variant,
  pageSize = 9999,
}: {
  path?: string;
  namespace?: string;
  featured?: boolean;
  variant?: ExhibitionVariant;
  pageSize?: number;
} = {}) => {
  return request<NodeEntity[]>('POST', URL_PATH, {
    filter: {
      page_size: pageSize,
      uri: { path, namespace: { l10n: namespace } },
      content: deleteNil({
        type: DJEDI_EXHIBITION_TYPE,
        'content.variant': variant,
        'content.featured': featured,
      }),
    },
    values: ['content', 'type', 'meta'],
  });
};

export const getPageNode = ({
  namespace = 'jacksons',
  pageSize = 9999,
  path,
}: {
  path?: string;
  namespace?: string;
  pageSize?: number;
} = {}) => {
  return request<NodeEntity[]>('POST', URL_PATH, {
    filter: {
      page_size: pageSize,
      uri: { path, namespace: { l10n: namespace } },
      content: {
        type: DJEDI_PAGE_TYPE,
      },
    },
    values: ['content', 'type', 'meta'],
  });
};
